import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { FirebaseService } from '../../services/firebase.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireList } from '@angular/fire/database';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  userInfo : any;
  pending : boolean = false;
  pendingLists$ : AngularFireList<{}>
  pendingLists : Observable<any[]>;
  isChecked = true;
  formGroup: FormGroup;  
  p: number = 1;

  constructor(private authService: AuthService, private _fireService: FirebaseService, formBuilder: FormBuilder) {   }

  ngOnInit() {
    this.authService.getLocalUserProfile();
    this.userInfo = this.authService.user;
    this.getLists();
  }

  getLists() {
    this.pending = !this.pending;
    this.pendingLists$ = this._fireService.getLists(this.pending);
    this.pendingLists = this.pendingLists$.snapshotChanges().pipe(
      map(changes => changes.map(c =>  ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
  }

  logOut() {
    this.authService.logOut();
  }

  delete(id) {
    if(window.confirm("Do you really want to Delete?")) {
      this._fireService.deleteList(id);
    }
  }
}
