import { Component, OnInit, HostListener } from '@angular/core';
import { AngularFireObject } from '@angular/fire/database';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { FirebaseService } from '../../services/firebase.service';
import { Observable, Subject } from 'rxjs';
import { buffer, debounceTime, take, last, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-shopping-list',
  templateUrl: './shopping-list.component.html',
  styleUrls: ['./shopping-list.component.scss']
})
export class ShoppingListComponent implements OnInit {

  title = 'Noble';
  items: any;
  order$: AngularFireObject<{}>;
  order: any;
  orderTotal: number = 0;
  myForm: FormGroup;
  listNum: number;
  barcode: any;
  isLoading: boolean = false;
  barcodeSubject = new Subject<any>();
  barcodeObservable: Observable<any> = this.barcodeSubject.asObservable();
  mySearch = new FormControl();

  constructor(private _fireService: FirebaseService, private formBuilder: FormBuilder) { }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.barcodeSubject.next(event.key);
  }

  ngOnInit() {

    this.myForm = this.formBuilder.group({
      items: this.formBuilder.array([])
    });

    this.myForm.get('items').valueChanges.subscribe(items => {
      this.orderTotal = 0;
      items.forEach(element => {
        this.orderTotal += element['qty'] * element['price'];
      });
    });

    this.barcodeObservable.pipe(
      buffer(this.barcodeObservable.pipe(debounceTime(100))),
    ).subscribe(barcode => {
      if (barcode.length > 6) {

        this.barcode = barcode.join(""); //might need to validate bardcode input for bad chars
        console.log(barcode);
        console.log(this.barcode);
        this._fireService.getItemByUPC(this.barcode).valueChanges().subscribe(item => {
          if (item) {
            console.log(item);
            this.addItem(item);
          }
          else {
            this._fireService.getItemByItemId(this.barcode).valueChanges().subscribe(item => {
              if (item) {
                this.addItem(item);
              }
              else {
                alert('Item not found.');
              }
            })
          }
        })
      }
    });

    this.items = this.mySearch.valueChanges.pipe(debounceTime(200)).pipe(
      switchMap((item) => this._fireService.searchForItem(item, item + "\uf8ff"))
    );

  }

  selectItem(barcode) {
    this.barcode = barcode.split(/\|/);
    if (this.barcode[0] && this.barcode[0].length > 0) {
       this._fireService.getItemByUPC(this.barcode[0]).valueChanges().subscribe(item => {
          if (item) this.addItem(item);
          else alert('Item not found.');
       });
    }
    else if (this.barcode[1]) {
       this._fireService.getItemByItemId(this.barcode[1]).valueChanges().subscribe(item => {
          if (item) this.addItem(item);
          else alert('Item not found.');
       });
    }
 }

  get itemForms() {
    return this.myForm.get('items') as FormArray
  }

  deleteItem(i) {
    this.itemForms.removeAt(i);
  }

  addItem(element) {

    var elementPos = -1;
    for (let i = 0; i < this.itemForms.controls.length; i++) {
       if (this.itemForms.controls[i].get('upc').value == element.upc && element.upc.length > 0) elementPos = i;
       else if (this.itemForms.controls[i].get('name').value == element.name && element.name.length > 0) elementPos = i;
    }
    if (elementPos == -1) {
       const item = this.formBuilder.group({
          image: [element.image],
          internal_id: [element.internal_id],
          mpn: [element.mpn],
          manu: [element.manu],
          name: [element.name],
          qty: [1, Validators.required],
          price: [element.price],
          upc: [element.upc],
          desc: [element.desc]
       });
       this.itemForms.push(item);
    }
    else {
       var qty = this.itemForms.controls[elementPos].get('qty').value + 1;
       this.itemForms.controls[elementPos].get('qty').setValue(qty);
    }
 }


  buildForm() {

    this.order.items.forEach(element => {
      const item = this.formBuilder.group({
        image: [element.image],
        internal_id: [element.internal_id],
        mpn: [element.mpn],
        manu: [element.manu],
        name: [element.name],
        qty: [1, Validators.required],
        price: [element.price],
        upc: [element.upc],
        desc: [element.desc]
      });
      this.itemForms.push(item);
    });
  }

  clearItems() {
    const control = <FormArray>this.myForm.controls['items'];

    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i)
    }
  }

  submitList() {
    this.isLoading = true;
    console.log(this.myForm.value);
    if (this.itemForms.length == 0) { alert('Your shopping list is empty. Please scan an item before submitting.') }
    else {
      this._fireService.createList(this.myForm.value);
      this._fireService.subject.subscribe(listNum => {
        this.listNum = listNum;
      });
    }
  }

}
