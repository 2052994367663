import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseService } from '../services/firebase.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private user: AngularFireAuth, private userAuthService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.userAuthService.user) return true;
    else {
      this.userAuthService.getLocalUserProfile();
      if(this.userAuthService.user) {
        console.log(this.userAuthService.user);
        return true;
      }
      else {
        console.log('need to authenticate');
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
      }
    }  
  }
  
}
