import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShoppingListComponent } from './components/shopping-list/shopping-list.component';
import { LoginComponent } from './components/login/login.component';
import { AdminComponent } from './components/admin/admin.component';
import { AdminShoppingListComponent } from './components/admin-shopping-list/admin-shopping-list.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: 'shopping-list', component: ShoppingListComponent},
  { path: 'login', component: LoginComponent},
  { path: 'admin', canActivate: [AuthGuard], runGuardsAndResolvers: 'always', component: AdminComponent },
  { path: 'admin-shopping-list/:id', canActivate: [AuthGuard], runGuardsAndResolvers: 'always', component: AdminShoppingListComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [ ShoppingListComponent,  AdminComponent]
