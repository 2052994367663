import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { take, switchMap, combineAll, mergeAll, mergeMap } from 'rxjs/operators';
import { Subject, concat, combineLatest, merge, forkJoin, zip } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DefaultFlexOrderDirective } from '@angular/flex-layout';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  record: AngularFireObject<{}> = null;
  listNum: number = 1;
  subject: Subject<number> = new Subject<number>();

  
  
  constructor(private fb: AngularFireDatabase, private http: HttpClient, private firebaseAuth: AngularFireAuth) {

  }

  getItemByUPC(upccode: string): AngularFireObject<{}> {
    this.record = this.fb.object('/upccode/'+upccode);
    return this.record;
  }  

  getItemByItemId(mpn: string): AngularFireObject<{}> {
    this.record = this.fb.object('/itemid/'+mpn);
    return this.record;
  }  

  getContactByEmail(internalid: string) {
    return this.fb.object('/contacts/'+internalid);
  }    

  searchForItem(start, end) {
    return zip(
      this.fb.list('/upccode/', ref => ref.orderByChild('upc').limitToFirst(25).startAt(start).endAt(end)).valueChanges()
    , this.fb.list('/itemid/', ref => ref.orderByChild('name').limitToFirst(25).startAt(start.toUpperCase()).endAt(end.toUpperCase())).valueChanges()
    , this.fb.list('/itemid/', ref => ref.orderByChild('mpn').limitToFirst(25).startAt(start.toUpperCase()).endAt(end.toUpperCase())).valueChanges()
    , (upc, name, mpn) => { return upc.concat(name).concat(mpn); }
    );
  }

  searchForContact(start, end) {
    return this.fb.list('/contacts/', ref => ref.orderByChild('email').limitToFirst(25).startAt(start).endAt(end));
  }

  checkDuplicateEmail(email) {
    return this.fb.list('/contacts/', ref => ref.orderByChild('email').equalTo(email));
  }


  getLists(status: boolean) {
    return this.fb.list('/productlists/', ref => ref.orderByChild('pending').equalTo(status));
  }

  getList(id: string): AngularFireObject<{}> {
    return this.fb.object('/productlists/'+id);
  }

  deleteList(id: string): void {
    this.fb.object('/productlists/'+id).remove();
  }  

  async createList(order) {

    var result = await this.firebaseAuth.auth.signInAnonymously();
    this.getLastItem().pipe(take(1)).subscribe((lastItem) => {
    
      if(lastItem.length != 0) this.listNum = lastItem[0]['listnumber']+1;
      
      var id = this.fb.list('/productlists/').push({}).key;
      order['listnumber'] = this.listNum;
      order['pending'] = true;
      order['createdAt'] = new Date();
      this.fb.object('/productlists/'+id).update(order);
      this.subject.next(this.listNum);
      
    });      
  }

  

  saveList(id, order) {
      order.pending = false;
      return this.http.post('https://us-central1-instore-hawaii.cloudfunctions.net/netsuiteCreate', order);
 
  }

  updateList(id, order) {
    return this.fb.object('/productlists/'+id).update(order);
  }

  addUser(order) {
    return this.fb.object('/contacts/'+order.internalid).update({ email : order.email, firstname : order.firstname, lastname : order.lastname, internalid : order.internalid, phone : order.phone });
  }  

  getLastItem() {
    return this.fb.list('/productlists/', ref => ref.orderByChild('listnumber').limitToLast(1)).valueChanges();
  }

}
