import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { HttpClientModule, HttpClient } from '@angular/common/http';

import { FlexLayoutModule } from '@angular/flex-layout';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {
	MatSlideToggleModule, MatButtonModule, MatBadgeModule, MatCardModule, MatMenuModule, MatToolbarModule, MatIconModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatProgressSpinnerModule,
	MatTableModule, MatExpansionModule, MatSelectModule, MatSnackBarModule, MatTooltipModule, MatChipsModule, MatListModule, MatSidenavModule,
	MatTabsModule, MatProgressBarModule, MatCheckboxModule, MatSliderModule, MatRadioModule, MatDialogModule, MatGridListModule
} from '@angular/material';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { NgxPaginationModule } from 'ngx-pagination';

import { FirebaseService } from './services/firebase.service';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { ShoppingListComponent } from './components/shopping-list/shopping-list.component';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AdminComponent } from './components/admin/admin.component';
import { AdminShoppingListComponent } from './components/admin-shopping-list/admin-shopping-list.component';
import { ReversePipe } from './pipes/reverse.pipe'

const appRoutes: Routes = [
	{
		path: '',
		component: MainPageComponent
	},
	{
		path: 'shopping-list',
		component: ShoppingListComponent

	},
	{
		path: 'login',
		component: LoginComponent

	},
	{
		path: 'admin-shopping-list',
		component: AdminShoppingListComponent

	}

]

export const firebaseConfig = {
	apiKey: "AIzaSyANqCktiwlRCozXWfoczfdsVM1_-ZLVpkI",
	authDomain: "instore-hawaii.firebaseapp.com",
	databaseURL: "https://instore-hawaii.firebaseio.com",
	projectId: "instore-hawaii",
	storageBucket: "instore-hawaii.appspot.com",
	messagingSenderId: "939689461481",
	appId: "1:939689461481:web:26d04ef4557cb8b1"
};

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
		MainPageComponent,
		ShoppingListComponent,
		LoginComponent,
		routingComponents,
		AdminComponent,
		AdminShoppingListComponent,
		ReversePipe,
	],
	imports: [
		RouterModule.forRoot(appRoutes),
		BrowserModule,
		BrowserAnimationsModule,
		FlexLayoutModule,
		FormsModule,
		HttpClientModule,
		ReactiveFormsModule,
		AngularFireModule.initializeApp(firebaseConfig),
		AngularFirestoreModule,
		AngularFireDatabaseModule,
		AngularFireAuthModule,
		AngularFireStorageModule,
		MatButtonModule,
		MatCardModule,
		MatMenuModule,
		MatToolbarModule,
		MatIconModule,
		MatBadgeModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatProgressSpinnerModule,
		MatTableModule,
		MatExpansionModule,
		MatSelectModule,
		MatSnackBarModule,
		MatTooltipModule,
		MatChipsModule,
		MatListModule,
		MatSidenavModule,
		MatTabsModule,
		MatProgressBarModule,
		MatCheckboxModule,
		MatSliderModule,
		MatRadioModule,
		MatDialogModule,
		MatGridListModule,
		MatSlideToggleModule,
		MatCheckboxModule,
		MatAutocompleteModule,
		NgbModule,
		NgxPaginationModule,
		AppRoutingModule
	],
	providers: [
		{ provide: FirestoreSettingsToken, useValue: {} },
		FirebaseService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
