import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {



  constructor(private authService: AuthService) { }

  ngOnInit() {
  }

  public get userInfo() {
    return this.authService.user;
  }

  logOut() {
    this.authService.logOut();
  }


}
